import { combineReducers } from "redux";

import slots from "./slots";
import shippings from "./shippings";
import accounts from "./accounts";
import returns from "./returns";
import bookings from "./bookings";
import services from "./services";
import contracts from "./contracts";
import language from "./language";
import sidebarStatus from "./sidebarStatus";
import storages from "./storages";
import imports from "./import";

export default combineReducers({
  slots,
  shippings,
  accounts,
  returns,
  bookings,
  services,
  contracts,
  language,
  sidebarStatus,
  storages,
  imports,
});
