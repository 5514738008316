import axios from "axios";
import api from "../api/index";

const IMPORT_CSV_REQUEST = "TYP/IMPORT_CSV_REQUEST";
const IMPORT_CSV_SUCCESS = "TYP/IMPORT_CSV_SUCCESS";
const IMPORT_CSV_FAILURE = "TYP/IMPORT_CSV_FAILURE";

const IMPORT_SHIPPING_FROM_CSV_REQUEST = "TYP/IMPORT_SHIPPING_FROM_CSV_REQUEST";
const IMPORT_SHIPPING_FROM_CSV_SUCCESS = "TYP/IMPORT_SHIPPING_FROM_CSV_SUCCESS";
const IMPORT_SHIPPING_FROM_CSV_FAILURE = "TYP/IMPORT_SHIPPING_FROM_CSV_FAILURE";

const ERASE_CSV_ORDER = "TYP/ERASE_CSV_ORDER";

const initialState = {
  loading: false,
  error: false,
  importedCsv: "",
  shippingImported: false,
};

export default function reducerContracts(state = initialState, action) {
  switch (action.type) {
    case IMPORT_CSV_REQUEST:
      return { ...state, loading: true, error: false };
    case IMPORT_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
        importedCsv: action.payload,
      };
    case IMPORT_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        importedCsv: "",
        error: action.payload,
      };
    case IMPORT_SHIPPING_FROM_CSV_REQUEST:
      return { ...state, loading: true, error: false };
    case IMPORT_SHIPPING_FROM_CSV_SUCCESS:
      return {
        ...state,

        loading: false,
        importedCsv: state.importedCsv.map((el) => {
          if (el.reference === action.payload) {
            return {
              ...el,
              importStatus: true,
            };
          } else {
            return el;
          }
        }),
        shippingImported: true,
      };
    case IMPORT_SHIPPING_FROM_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        importedCsv: state.importedCsv.map((el) => {
          if (el.reference === action.payload.reference) {
            return {
              ...el,
              importStatus: false,
              errorImport: action.payload.error.response.data.message,
            };
          } else {
            return el;
          }
        }),

        error: action.payload.error,
      };

    case ERASE_CSV_ORDER:
      return {
        ...state,
        importedCsv: "",
        shippingImported: false,
      };

    default:
      return state;
  }
}

export const importCsv = (file) => {
  return async (dispatch) => {
    dispatch({
      type: IMPORT_CSV_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.importCsv(file);
      const importedCsv = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: IMPORT_CSV_SUCCESS,
        payload: importedCsv.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: IMPORT_CSV_FAILURE,
        payload: error,
      });
    }
  };
};

export const eraseCsvOrder = () => {
  return async (dispatch) => {
    dispatch({
      type: ERASE_CSV_ORDER,
      payload: "",
    });
  };
};

export const createShippingFromCsv = (shipping) => {
  return async (dispatch) => {
    dispatch({
      type: IMPORT_SHIPPING_FROM_CSV_REQUEST,
    });
    try {
      const { url, method, headers, data } = api.createShipping(shipping);
      const importedCsv = await axios({
        url,
        method,
        headers,
        data,
      });
      dispatch({
        type: IMPORT_SHIPPING_FROM_CSV_SUCCESS,
        payload: importedCsv.data.reference,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: IMPORT_SHIPPING_FROM_CSV_FAILURE,
        payload: { reference: shipping.reference, error },
      });
    }
  };
};
