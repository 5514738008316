import axios from "axios";
import Bluebird from "bluebird";
import api from "../api/index";

const GET_ALL_STORAGES_REQUEST = "TYP/GET_ALL_STORAGES_REQUEST";
const GET_ALL_STORAGES_SUCCESS = "TYP/GET_ALL_STORAGES_SUCCESS";
const GET_ALL_STORAGES_LOADING_SUCCESS = "TYP/GET_ALL_STORAGES_LOADING_SUCCESS";
const GET_ALL_STORAGES_FAILURE = "TYP/GET_ALL_STORAGES_FAILURE";

const GET_STORAGE_BY_ID_REQUEST = "TYP/GET_STORAGE_BY_ID_REQUEST";
const GET_STORAGE_BY_ID_SUCCESS = "TYP/GET_STORAGE_BY_ID_SUCCESS";
const GET_STORAGE_BY_ID_FAILURE = "TYP/GET_STORAGE_BY_ID_FAILURE";

const GET_RELEASE_MODES_REQUEST = "TYP/GET_RELEASE_MODES_REQUEST";
const GET_RELEASE_MODES_SUCCESS = "TYP/GET_RELEASE_MODES_SUCCESS";
const GET_RELEASE_MODES_FAILURE = "TYP/GET_RELEASE_MODES_FAILURE";

const GET_REASONS_REQUEST = "TYP/GET_REASONS_REQUEST";
const GET_REASONS_SUCCESS = "TYP/GET_REASONS_SUCCESS";
const GET_REASONS_FAILURE = "TYP/GET_REASONS_FAILURE";

const GET_PROVISION_REQUEST = "TYP/GET_PROVISION_REQUEST";
const GET_PROVISION_SUCCESS = "TYP/GET_PROVISION_SUCCESS";
const GET_PROVISION_FAILURE = "TYP/GET_PROVISION_FAILURE";

const ERASE_STORAGE = "TYP/ERASE_STORAGE";

// const SAVE_RELEASE_PROVISION_REQUEST = "TYP/SAVE_RELEASE_PROVISION_REQUEST";
// const SAVE_RELEASE_PROVISION_SUCCESS = "TYP/SAVE_RELEASE_PROVISION_SUCCESS";
// const SAVE_RELEASE_PROVISION_FAILURE = "TYP/SAVE_RELEASE_PROVISION_FAILURE";

const initialState = {
  loading: false,
  loadingReferences: false,
  data: { warehousesStorages: [] },
  error: false,
  singleStorage: null,
  releaseModes: [],
  reasons: [],
  provisionLabel: null,
  provisionId: null,
  count: 10,
};

export default function reducerStorages(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_STORAGES_REQUEST:
      return { ...state, loading: true, error: false };
    case GET_ALL_STORAGES_LOADING_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingReferences: true,
        data: action.payload,
        count: action.payload.count,
      };
    case GET_ALL_STORAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        loadingReferences: false,
        error: false,
      };

    case GET_ALL_STORAGES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_STORAGE_BY_ID_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_STORAGE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        singleStorage: action.payload,
        provisionLabel: null,
      };

    case GET_STORAGE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
        provisionLabel: null,
      };
    case GET_RELEASE_MODES_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_RELEASE_MODES_SUCCESS:
      return {
        ...state,
        loading: false,
        releaseModes: action.payload,
      };

    case GET_RELEASE_MODES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_RELEASE_MODES_REQUEST:
      return { ...state, loading: true, error: false };

    case GET_RELEASE_MODES_SUCCESS:
      return {
        ...state,
        loading: false,
        reasons: action.payload,
      };

    case GET_RELEASE_MODES_FAILURE:
      return { ...state, loading: false, data: [], error: action.payload };
    case GET_PROVISION_REQUEST:
      return { ...state, loadingProvision: true, errorProvision: false };

    case GET_PROVISION_SUCCESS:
      return {
        ...state,
        loadingProvision: false,
        provisionLabel: action.payload,
      };

    case GET_PROVISION_FAILURE:
      return {
        ...state,
        provisionLabel: null,
        loadingProvision: false,
        errorProvision: action.payload,
      };

    case ERASE_STORAGE:
      return {
        ...state,
        singleStorage: null,
      };
    default:
      return state;
  }
}

export const getAllStorages = (reference) => {
  // console.log('reference', reference)
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_STORAGES_REQUEST,
    });

    try {
      const { url, method, headers, params } = api.getAllStorages(reference);

      let storages = await axios({
        url,
        method,
        headers,
        params,
      });
      dispatch({
        type: GET_ALL_STORAGES_LOADING_SUCCESS,
        payload: storages.data,
      });
      const provStorages = await Bluebird.mapSeries(
        storages.data.warehouseStorages,
        async (storage) => {
          try {
            const { url, method, headers } = api.getProvision(storage.id);

            const storageProvision = await axios({
              url,
              method,
              headers,
            });
            return {
              ...storage,
              provisionLabel: storageProvision.data.releaseMode.description,
              provisionId: storageProvision.data.releaseMode.id,
            };
          } catch (err) {
            return {
              ...storage,
              provisionLabel: "Disposizioni non ancora ricevute",
              provisionId: null,
            };
          }
        }
      );
      const warehouseStorages = await Bluebird.mapSeries(
        provStorages,
        async (storage) => {
          try {
            const { url, method, headers } = api.getShippingById(
              storage.storedShipmentId
            );

            const shipping = await axios({
              url,
              method,
              headers,
            });
            return {
              ...storage,
              reference: shipping.data.reference,
              customerReference: shipping.data.customerReference,
            };
          } catch (err) {
            return {
              ...storage,
              provisionLabel: null,
              provisionId: null,
            };
          }
        }
      );
      dispatch({
        type: GET_ALL_STORAGES_SUCCESS,
        payload: { warehouseStorages },
      });

      return { warehouseStorages };
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_STORAGES_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSingleStorage = (reference) => {
  // console.log('reference', reference)
  return async (dispatch) => {
    dispatch({
      type: GET_ALL_STORAGES_REQUEST,
    });

    try {
      const { url, method, headers, params } = api.getAllStorages(reference);

      let storages = await axios({
        url,
        method,
        headers,
        params,
      });
      const warehouseStorages = await Bluebird.mapSeries(
        storages.data.warehouseStorages,
        async (storage) => {
          try {
            const { url, method, headers } = api.getProvision(storage.id);

            const storageProvision = await axios({
              url,
              method,
              headers,
            });
            return {
              ...storage,
              provisionLabel: storageProvision.data.releaseMode.description,
              provisionId: storageProvision.data.releaseMode.id,
            };
          } catch (err) {
            return {
              ...storage,
              provisionLabel: "Disposizioni non ancora ricevute",
              provisionId: null,
            };
          }
        }
      );
      // console.log("STORAGE", warehouseStorages);
      dispatch({
        type: GET_ALL_STORAGES_SUCCESS,
        payload: { warehouseStorages },
      });

      return { warehouseStorages };
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_ALL_STORAGES_FAILURE,
        payload: error,
      });
    }
  };
};

export const getAllReleaseModes = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_RELEASE_MODES_REQUEST,
    });

    try {
      const { url, method, headers } = api.getAllReleaseModes();

      let modes = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_RELEASE_MODES_SUCCESS,
        payload: modes.data,
      });
      // console.log(" SINGLE modes", modes.data);
      return modes.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_RELEASE_MODES_FAILURE,
        payload: error,
      });
    }
  };
};

export const getStorageById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_STORAGE_BY_ID_REQUEST,
    });

    try {
      const { url, method, headers } = api.getStorageById(id);

      let storage = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_STORAGE_BY_ID_SUCCESS,
        payload: storage.data,
      });
      return storage.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_STORAGE_BY_ID_FAILURE,
        payload: error,
      });
    }
  };
};

export const getAllReasons = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_REASONS_REQUEST,
    });
    try {
      const { url, method, headers } = api.getAllReasons();

      let reasons = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_REASONS_SUCCESS,
        payload: reasons.data,
      });
      console.log(" SINGLE reasons", reasons.data);
      return reasons.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);

      dispatch({
        type: GET_REASONS_FAILURE,
        payload: error,
      });
    }
  };
};

export const saveNewReleaseProvision = (id, data) => {
  return async () => {
    try {
      const { url, method, headers } = api.saveNewReleaseProvision(id);

      let newProvision = await axios({
        url,
        method,
        headers,
        data,
      });

      // console.log("new provision", newProvision.data);
      return newProvision.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
    }
  };
};

export const getProvision = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PROVISION_REQUEST,
    });
    try {
      const { url, method, headers } = api.getProvision(id);

      let provision = await axios({
        url,
        method,
        headers,
      });
      dispatch({
        type: GET_PROVISION_SUCCESS,
        payload: provision.data,
      });

      console.log("new provision", provision.data);
      return provision.data;
    } catch (error) {
      console.log("error", error);
      console.log("error", error.response);
      dispatch({
        type: GET_PROVISION_FAILURE,
        payload: error,
      });
    }
  };
};

export const eraseStorage = () => {
  return async (dispatch) => {
    dispatch({
      type: ERASE_STORAGE,
    });
  };
};
